import React from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import { useTranslation } from "react-i18next";
import FormControlController from "../../../components/form/FormControlController";
import ReactSelectController from "../../../components/form/ReactSelectController";
import { Form, Submit } from "../Auth.styled";
import { RegisterContext } from "./RegisterContext";
import useCountryOptions from "../../../utils/hooks/useCountryOptions";

type FormValues = {
  name: string;
  lastName: string;
  company: string;
  country: string;
  email_consent: boolean;
};

export default function RegisterStepTwo() {
  const { t } = useTranslation();
  const { formState, setFormState, setStep } = React.useContext(RegisterContext);
  const countryOptions = useCountryOptions();

  const validationSchema = React.useMemo(
    () =>
      Joi.object({
        country: Joi.string()
          .required()
          .messages({ "string.empty": t("auth.register.step2.mandatory") }),
        name: Joi.string().allow("").optional(),
        lastName: Joi.string().allow("").optional(),
        company: Joi.string().allow("").optional(),
      }).unknown(true),
    [t]
  );

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: formState.name || "",
      lastName: formState.lastName || "",
      company: formState.company || "",
      country: formState.country || "",
    },
  });

  const onSubmit = (data: FormValues) => {
    const { company, country, name, lastName } = data;
    setFormState({ ...formState, name, lastName, company, country });
    setStep(3);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControlController name="name" control={control} label={t("auth.register.step2.name")} />
      <FormControlController name="lastName" control={control} label={t("auth.register.step2.lastName")} />
      <FormControlController name="company" control={control} label={t("auth.register.step2.company")} />
      <ReactSelectController
        options={countryOptions}
        name="country"
        control={control}
        label={t("auth.register.step2.country")}
        placeholder={t("auth.register.step2.countryPlaceholder")}
        required
      />

      <Submit style={{ marginTop: "64px" }}>{t("auth.register.step2.submit")}</Submit>
    </Form>
  );
}
