import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";
import device from "../../theme/utils/breakpoints";
import { ButtonV2ColorsCss, ButtonV2ColorsActiveCss, NavSmallIconButton } from "../UiKit/Button";
import { FlexCenterCSS, FlexCenterWrapper } from "../UiKit/Helpers/StyledCss";

export const Navbar = styled.nav<{ $navOpened: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background-color: #fff;
  height: ${(props) => props.theme.navBar.height};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text2};
  border-bottom: 1px solid ${(props) => props.theme.text4};

  ${(props) =>
    props.$navOpened &&
    css`
      @media ${device.L} {
        box-shadow: none;
      }
    `}

  a {
    svg {
      width: 360px;
      padding-top: 6px;
      height: auto;

      @media ${device.L} {
        padding-top: 2px;
        width: 260px;
        box-shadow: none;
      }
    }
  }

  a {
    text-decoration: none;
  }
`;

export const Separator = styled.div<{ $onlyOnSmallScreens?: boolean }>`
  background: ${(props) => props.theme.text4};
  height: 1px;
  flex-shrink: 0;

  ${(props) =>
    props.$onlyOnSmallScreens &&
    css`
      display: none;

      @media ${device.L} {
        display: block;
      }
    `}
`;

export const NavWrapper = styled(FlexCenterWrapper)`
  margin-left: 54px;
  gap: 22px;

  @media ${device.M} {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NavActions = styled(FlexCenterWrapper)<{ $smallGap?: boolean; $forSideNav?: boolean }>`
  margin-left: auto;
  gap: 16px;

  ${(props) => props.$smallGap && "gap: 16px;"}
  ${(props) =>
    props.$forSideNav &&
    css`
      display: none;
      margin-left: 21px;
      margin-top: 12px;
      margin-bottom: 24px;
      @media ${device.L} {
        display: flex;
      }
    `}
`;

export const Button = styled(NavSmallIconButton)`
  position: relative;
`;

export const NavButton = css`
  ${ButtonV2ColorsCss};
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  cursor: pointer;
  transition: color background 0.1s;
  height: 60px;
  padding: 0 21px;
  text-decoration: none;
  border-radius: 12px;
  text-transform: uppercase;
  border: none;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  flex-shrink: 0;

  svg {
    flex-shrink: 0;
  }
`;

export const Link = styled(RouterLink)<{ $active?: boolean; $disabled?: boolean; $level2?: boolean; $hide?: boolean }>`
  ${NavButton}
  display: flex;

  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `};

  ${(props) =>
    props.$active &&
    css`
      ${ButtonV2ColorsActiveCss}

      &:hover {
        ${ButtonV2ColorsActiveCss}
      }
    `};

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.$level2 &&
    css`
      text-transform: none;
      height: 40px;
      margin-left: 22px;
      padding: 0 14px;
      font-weight: 300;
      letter-spacing: normal;
      font-size: 16px;
    `}
`;

export const ToggleButton = styled.button<{ $active?: boolean }>`
  ${NavButton}
  margin-top: auto;
  width: 60px;
  justify-content: center;
  align-self: end;

  @media ${device.L} {
    display: none;
  }

  ${(props) =>
    props.$active &&
    css`
      transform: rotate(180deg);
    `};
`;

export const NavContentWrapper = styled.div`
  ${FlexCenterCSS};
  width: 100%;

  @media ${device.L} {
    display: none;
  }
`;

export const Container = styled.div<{ $landingPage?: boolean }>`
  ${FlexCenterCSS};
  width: 100%;
  padding: 0 25px;

  ${(props) =>
    props.$landingPage &&
    css`
      max-width: calc(${props.theme.smallContainerMaxWidth});
      width: 100%;
      margin: 0 auto;
    `}

  @media ${device.L} {
    padding: 0 16px;
  }
`;

export const Level2Wrapper = styled.div<{ $opened?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SideBarWrapper = styled.div<{ $opened?: boolean }>`
  background-color: #fff;
  height: calc(100vh - ${(props) => props.theme.navBar.height});
  width: 80px;
  position: sticky;
  top: ${(props) => props.theme.navBar.height};
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  flex-shrink: 0;
  border-right: 1px solid ${(props) => props.theme.text4};

  @media ${device.L} {
    position: fixed;
    top: ${(props) => props.theme.navBar.height};
    z-index: 100;
    display: none;
    left: 0;
    right: 0;
    width: 100%;
    padding-right: calc(100vw - 280px);
    height: auto;
    box-shadow: ${(props) => props.theme.navBoxShadow};
    max-height: calc(100vh - ${(props) => props.theme.navBar.height});
    overflow: auto;

    ${(props) =>
      props.$opened &&
      css`
        display: flex;
        /* width: 280px; */
      `}
  }

  ${(props) =>
    props.$opened &&
    css`
      width: 280px;
    `}

  ${(props) =>
    !props.$opened &&
    css`
      ${Link} {
        margin-left: 0;
        justify-content: center;
        span {
          display: none;
        }
      }

      ${Level2Wrapper} {
        gap: 12px;

        ${Link} {
          height: 60px;
        }
      }
    `}
`;
