import React from "react";
import styled, { css } from "styled-components";
import { Logo2025WithSlogan } from "@mediately/iconkit-web";
import FooterMediatelySection from "./FooterMediatelySection";
import FooterInsightsSection from "./FooterInsightsSection";
import FooterAboutUsSection from "./FooterAboutUsSection";
import FooterProductsSection from "./FooterProductsSection";
import FooterAppsSection from "./FooterAppsSection";
import Container from "../../UiKit/Container";
import device from "../../../theme/utils/breakpoints";
import { UserContext } from "../../../contexts/UserContext";

const Sections = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 78px;
  color: ${(props) => props.theme.blueSuperDark};
  gap: 36px;
  font-size: 14px;
  line-height: 18px;

  a {
    color: ${(props) => props.theme.blueSuperDark};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${device.M} {
    padding-bottom: 32px;
    flex-wrap: wrap;
    justify-content: space-between;

    > :nth-child(2) {
      margin-left: unset;
    }
    > :nth-child(1) {
      min-width: 100%;
    }
  }

  @media ${device.S} {
    flex-direction: column;

    > :nth-child(2) {
      margin-left: 0;
    }
  }
`;

const LogoWrapper = styled.div`
  margin-top: 52px;
  padding-bottom: 32px;
  border-bottom: 1px solid #b9bdc6;
  margin-bottom: 24px;

  svg {
    width: 203px;
    height: auto;

    @media ${device.M} {
      width: 160px;
    }
  }
`;

const StContainer = styled(Container)<{ $landingPage?: boolean }>`
  ${(props) =>
    props.$landingPage &&
    css`
      max-width: calc(${props.theme.smallContainerMaxWidth} + 64px);
    `}
`;

export default function Footer() {
  const { user } = React.useContext(UserContext);

  return (
    <StContainer $landingPage={!user}>
      <LogoWrapper>
        <Logo2025WithSlogan />
      </LogoWrapper>
      <Sections>
        <FooterMediatelySection />
        <FooterInsightsSection />
        <FooterAboutUsSection />
        <FooterProductsSection />
        <FooterAppsSection />
      </Sections>
    </StContainer>
  );
}
