import React from "react";
import { AppBadgeAndroid, AppBadgeHuawei, AppBadgeIos } from "@mediately/iconkit-web";
import { useTranslation } from "react-i18next";
import FooterColumn from "./FooterColumn";

export default function FooterAppsSection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.apps.header")}>
      <a href="https://apps.apple.com/si/developer/modra-jagoda-d-o-o/id546792104">
        <AppBadgeIos />
      </a>
      <a href="https://play.google.com/store/apps/dev?id=5854872199351427022">
        <AppBadgeAndroid />
      </a>
      <a href="https://appgallery.huawei.com/#/app/C103823905">
        <AppBadgeHuawei />
      </a>
    </FooterColumn>
  );
}
