import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/mediately-insights-logo.svg";
import * as Styled from "./NavigationBar.styled";
import BurgerButton from "./BurgerButton/BurgerButton";
import NavActions from "./NavActions/NavActions";

export default function TopNavigationBar({
  setNavOpened,
  navOpened,
  landingPage,
}: {
  setNavOpened: React.Dispatch<React.SetStateAction<boolean>>;
  navOpened: boolean;
  landingPage?: boolean;
}) {
  return (
    <Styled.Navbar $navOpened={navOpened}>
      <Styled.Container $landingPage={landingPage}>
        <Link to="/">
          <Logo />
        </Link>

        <Styled.NavContentWrapper>
          <NavActions />
        </Styled.NavContentWrapper>

        <BurgerButton onClick={() => setNavOpened((opened) => !opened)} navOpened={navOpened} />
      </Styled.Container>
    </Styled.Navbar>
  );
}
