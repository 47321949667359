import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FooterColumn from "./FooterColumn";

const Text = styled.div`
  margin-bottom: 8px;

  p {
    margin: 0;
  }
`;

export default function FooterMediatelySection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.mediately.header")}>
      <Text>
        <p>{t("footer.mediately.services")}</p>
      </Text>
      <div>
        <div>
          {t("footer.mediately.email")} <a href="mailto: sales@mediately.co">sales@mediately.co</a>
        </div>
        <div>
          {t("footer.mediately.phone")} <a href="tel: +386 30 710 976">+386 30 710 976</a>
        </div>
      </div>
    </FooterColumn>
  );
}
