import React from "react";
import styled from "styled-components";

export const Header = styled.h3`
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 1.33;
  font-weight: 700;
  font-size: inherit;
  margin-bottom: 0;
`;

export const FooterColl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  svg {
    width: 133px;
    height: auto;
  }
`;

interface FooterColumnI {
  children: React.ReactElement | React.ReactElement[];
  title?: string;
}

export default function FooterColumn({ children, title }: FooterColumnI) {
  return (
    <FooterColl>
      {title && <Header>{title}</Header>}
      {children}
    </FooterColl>
  );
}
