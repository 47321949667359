import styled, { css } from "styled-components";
import UiKitContainer from "../../components/UiKit/Container";
import device from "../../theme/utils/breakpoints";

const TitleFont = css`
  font-family: ${(props) => props.theme.fontPoppins};
  font-size: 48px;
  line-height: 50px;

  @media ${device.S} {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const Heading1 = styled.h1`
  ${TitleFont};
  letter-spacing: 0.3px;
  margin-bottom: 32px;
  font-weight: 700;
  max-width: 500px;
  margin-bottom: 40px;
  color: ${(props) => props.theme.darkBlue};
`;

export const Heading2 = styled.h2<{ $center?: boolean }>`
  ${TitleFont};
  letter-spacing: 0.3px;
  margin-bottom: 39px;
  font-weight: 600;
  max-width: 922px;
  font-size: 42px;
  line-height: 1.1;
  color: ${(props) => props.theme.darkBlue};

  @media ${device.S} {
    font-size: 28px;
    line-height: 1.1;
  }

  ${(props) =>
    props.$center &&
    css`
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    `}
`;

export const Background = styled.div<{ $hero?: boolean }>`
  overflow: hidden;
  background-color: #fff;

  ${(props) =>
    props.$hero &&
    css`
      padding-top: 32px;
      padding-bottom: 152px;
      border-radius: 0px 0px 64px 64px;
      box-shadow: 2px 8px 20px 0px rgba(0, 0, 0, 0.15);
      z-index: 1;
      position: relative;
    `};
`;

export const SectionContainer = styled(UiKitContainer)<{ $section: "features" | "partners" | "cta" }>`
  max-width: calc(${(props) => props.theme.smallContainerMaxWidth});

  ${({ $section }) => {
    switch ($section) {
      case "features":
        return css`
          padding-top: 149px;
          padding-bottom: 149px;

          @media ${device.M} {
            padding-top: 100px;
            padding-bottom: 100px;
          }
          @media ${device.S} {
            padding-top: 50px;
            padding-bottom: 60px;
          }
        `;
      case "partners":
        return css`
          padding-top: 40px;
          padding-bottom: 164px;

          @media ${device.M} {
            padding-top: 40px;
            padding-bottom: 128px;
          }
          @media ${device.S} {
            padding-top: 40px;
            padding-bottom: 22px;
          }
        `;
      case "cta":
        return css`
          padding-top: 40px;
          padding-bottom: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media ${device.M} {
            padding-top: 40px;
            padding-bottom: 160px;
          }
          @media ${device.S} {
            padding-top: 40px;
            padding-bottom: 120px;
          }
        `;
      default:
        return "";
    }
  }};
`;

export const PartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @media ${device.M} {
    gap: 30px;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
`;

export const FeaturesRow = styled.div<{ $reverse?: boolean }>`
  display: flex;
  gap: 60px;
  align-items: center;

  @media ${device.M} {
    flex-direction: column;
    align-items: center;
    ${(props) => props.$reverse && "flex-direction: column;"}
  }
  @media ${device.M} {
    gap: 30px;
  }

  ${(props) => props.$reverse && "flex-direction: row-reverse;"}

  & + & {
    margin-top: 180px;

    @media ${device.M} {
      margin-top: 100px;
    }
    @media ${device.S} {
      margin-top: 60px;
    }
  }
`;

export const FeaturesColumn = styled.div<{ $img?: boolean }>`
  width: 48%;
  ${(props) => props.$img && "width: 52%;"}
  color: ${(props) => props.theme.darkBlue};

  @media ${device.M} {
    width: auto;
  }

  font-size: 21px;

  li {
    padding-left: 6px;
    font-weight: 500;
  }

  p {
    margin-top: 32px;
  }
`;

export const Container = styled(UiKitContainer)<{ $morePadding?: boolean }>`
  max-width: calc(${(props) => props.theme.smallContainerMaxWidth});
  display: flex;
  padding-top: 75px;
  gap: 120px;

  @media ${device.M} {
    flex-direction: column;
    gap: 64px;
  }

  @media ${device.S} {
    padding-top: 64px;
  }

  ${(props) =>
    props.$morePadding &&
    css`
      padding-top: 100px;
      padding-bottom: 100px;

      @media ${device.M} {
        padding-bottom: 50px;
      }
      @media ${device.S} {
        padding-top: 30px;
      }
    `}
`;

export const Column = styled.div`
  flex: 1;
`;

export const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background: #cfdefc;
  flex-shrink: 0;

  svg {
    path {
      fill: ${(props) => props.theme.darkBlue};
    }
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Text = styled.p<{ $xl?: boolean }>`
  max-width: 405px;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;

  @media ${device.S} {
    font-size: 16px;
    line-height: 24px;
  }

  ${(props) =>
    props.$xl &&
    css`
      text-align: center;
      max-width: 780px;
      font-weight: 600;
      font-size: 48px;
      line-height: 52px;
      margin-bottom: 48px;
      font-family: ${props.theme.fontSourceSans};

      @media ${device.S} {
        font-size: 32px;
        line-height: 40px;
      }
    `}
`;

export const ButtonContainer = styled.div`
  max-width: fit-content;
  margin-top: 50px;
`;

export const HeroImage = styled.img`
  max-width: 100%;
  /* transform: translateX(-45%); */
  pointer-events: none;

  @media ${device.M} {
    max-width: 100%;
    transform: none;
  }
`;

export const Image = styled.img<{ $withShadow?: boolean }>`
  ${(props) => props.$withShadow && "box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);"}
  max-width: 655px;
  width: 100%;
  border-radius: 32px;
`;

export const Video = styled.video`
  display: block;
  margin: 54px auto 24px;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
`;
