import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Table from "../../components/Table";
import { formatNumber } from "../../utils/numbers";
import { MDS_PER_SPECIALIZATION_KEYS } from "./utils";
import { MDsPerSpecializationSchema, MDsPerSpecializationStats } from "../../api";
import { UserContext } from "../../contexts/UserContext";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { FlexCenterCSS } from "../../components/UiKit/Helpers/StyledCss";
import { PagePeriodFilterContext } from "../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { getMdsPerSpecListExport } from "../../api/ReportListExport";
import { MdsPerSpecExportPropsI } from "./types";
import WithLoadingCard from "../../components/UiKit/WithLoadingCard";
import CellWithInfo from "../../components/Table/CellWithInfo";
import CountriesMap from "../../components/CountriesMap/CountriesMap";
import { SectionContainer } from "../../components/ReportPageComponents/StyledComponents";
import { Subtitle1, SubtleSubtitle } from "../../components/UiKit/Typography";
import HeaderCell from "../../components/Table/HeaderCell";

interface IMdsPerSpecializationTable {
  data?: MDsPerSpecializationSchema;
  isFetching?: boolean;
  selectedCountry?: string;
}

const getFormattedData = (
  data: Record<string, MDsPerSpecializationStats>,
  userInterests: string[],
  userInterest: boolean
) =>
  Object.keys(data)
    .filter((key) => (userInterest ? userInterests.includes(key) : !userInterests.includes(key)))
    .map((key) => data[key])
    .sort((a, b) => {
      if (a.translation && !!b.translation) {
        return a.translation.localeCompare(b.translation);
      }
      return -1;
    })
    .map((row) => ({
      ...row,
      translation: row.translation || "N/A",
      userInterest,
    }));

function TableFooterCell({
  cellKey,
  data,
  translation,
  tooltip,
}: {
  cellKey: string;
  data: number | undefined;
  translation: string;
  tooltip?: string;
}) {
  if (cellKey === "translation") {
    if (tooltip) {
      return <CellWithInfo id={`mdsPerSpecialization.table.footer.${cellKey}`} value={translation} tooltip={tooltip} />;
    }

    return <span>{translation}</span>;
  }
  return <span>{data ? formatNumber(data) : 0}</span>;
}

const StyledTranslation = styled.div`
  ${FlexCenterCSS};
  gap: 12px;
`;

function Translation({ value, favorite }: { value: string; favorite?: boolean }) {
  return (
    <StyledTranslation>
      {value}
      {favorite && <Star />}
    </StyledTranslation>
  );
}

export default function MdsPerSpecializationTable({ data, isFetching, selectedCountry }: IMdsPerSpecializationTable) {
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();
  const { mdsLimitedSelectedPeriod: selectedPeriod, mdsLimitedDateRangeReadable } =
    React.useContext(PagePeriodFilterContext);
  const [searchParams] = useSearchParams();

  const [mdsPerSpecExportProps, setMdsPerSpecExportProps] = React.useState<MdsPerSpecExportPropsI | null>(null);

  const handleSubmitClick = React.useCallback(() => {
    getMdsPerSpecListExport({
      exportContentType: mdsPerSpecExportProps!.contentType,
      from: selectedPeriod.from!,
      until: selectedPeriod.until!,
      country: selectedCountry!,
      orderBy: mdsPerSpecExportProps!.orderBy,
      ascending: mdsPerSpecExportProps!.ascending,
    });
  }, [mdsPerSpecExportProps, selectedPeriod, selectedCountry]);

  React.useEffect(() => {
    if (mdsPerSpecExportProps) {
      handleSubmitClick();
      setMdsPerSpecExportProps(null);
    }
  }, [mdsPerSpecExportProps, handleSubmitClick]);

  const formattedData = React.useMemo(() => {
    if (!data) {
      return undefined;
    }
    const userInterests = user?.specialization_interests || [];

    let filteredResults = [
      ...getFormattedData(data.per_spec, userInterests, true),
      ...getFormattedData(data.per_spec, userInterests, false),
    ];

    const search = searchParams.get("search");

    if (search) {
      filteredResults = filteredResults.filter((row) => row.translation.toLowerCase().includes(search.toLowerCase()));
    }

    return filteredResults;
  }, [data, user, searchParams]);

  const totals: any = React.useMemo(() => {
    if (formattedData) {
      const sumRows = formattedData?.reduce(
        (acc, row) => {
          acc.active_mds += row.active_mds;
          acc.sessions += row.sessions;
          acc.with_email_consent += row.with_email_consent;
          return acc;
        },
        { active_mds: 0, sessions: 0, with_email_consent: 0 }
      );

      return {
        ...sumRows,
        sessions_per_active_mds: sumRows.active_mds ? Math.round(sumRows.sessions / sumRows.active_mds) : 0,
      };
    }

    return {
      active_mds: 0,
      sessions: 0,
      sessions_per_active_mds: 0,
      with_email_consent: 0,
    };
  }, [formattedData]);

  const columns = React.useMemo(
    () => [
      ...MDS_PER_SPECIALIZATION_KEYS.map((key) => ({
        Header: (
          <HeaderCell
            id={`mdsPerSpecialization.table.${key}`}
            title={t(`mdsPerSpecialization.table.${key}`)}
            tooltipText={t(`mdsPerSpecialization.table.${key}Tooltip`)}
            tooltipEnabled={key !== "translation"}
          />
        ),
        accessor: key,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;

            return key === "translation" ? (
              <Translation value={record[key]} favorite={record.userInterest} />
            ) : (
              formatNumber(record[key])
            );
          }, [row]),
        Footer: (
          <TableFooterCell
            cellKey={key}
            translation={t("mdsPerSpecialization.table.footer")}
            data={key === "translation" ? undefined : totals[key]}
          />
        ),
      })),
    ],
    [totals, t]
  );

  const tableTitle = React.useMemo(() => t(`mdsPerSpecialization.table.title`), [t]);

  return (
    <SectionContainer>
      <div>
        <Subtitle1 $smallMargin>{t(`mdsPerSpecialization.map.title`)}</Subtitle1>
        <SubtleSubtitle>{mdsLimitedDateRangeReadable}</SubtleSubtitle>
        <CountriesMap />
      </div>

      <WithLoadingCard isLoading={!!isFetching && !formattedData} noBorder>
        {formattedData && (
          <Table
            columns={columns}
            data={formattedData}
            tableTitle={tableTitle}
            withGlobalFilter
            withExportButton
            horizontallyScrollableCard={false}
            setMdsPerSpecExportProps={setMdsPerSpecExportProps}
            withFooter
            collapseRows
          />
        )}
      </WithLoadingCard>
    </SectionContainer>
  );
}
