import React from "react";
import { COUNTRIES } from "../../../utils/constants";
import { CountryContext } from "../CountryContext";
import CountrySvgPath from "./CountryPath";

function Country({ country }: { country: string }) {
  const { selectedCountry, setTooltipCountry } = React.useContext(CountryContext);

  const onMouseEnter = React.useCallback(
    (e: any) => {
      const { id } = e.target.parentElement;

      if (id) {
        const position = e.target.parentElement.getBoundingClientRect();

        document.documentElement.style.setProperty("--tooltip-x", `${position.x + position.width + window.scrollX}px`);
        document.documentElement.style.setProperty("--tooltip-y", `${position.top + window.scrollY}px`);
        setTooltipCountry(id);
      }
    },
    [setTooltipCountry]
  );

  const onMouseLeave = React.useCallback(
    (e: any) => {
      const { id } = e.target.parentElement;
      if (id) {
        setTooltipCountry("");
      }
    },
    [setTooltipCountry]
  );

  const countrySvgPath = React.useMemo(() => <CountrySvgPath country={country} />, [country]);

  return (
    <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} id={country} data-selected={selectedCountry === country}>
      {countrySvgPath}
    </g>
  );
}

export default function ActiveCountries() {
  const { setSelectedCountry } = React.useContext(CountryContext);

  const onClick = React.useCallback(
    (e: any) => {
      const { id } = e.target.parentElement;

      if (id) {
        setSelectedCountry(id);
      }
    },
    [setSelectedCountry]
  );

  return (
    <g onClick={onClick}>
      {COUNTRIES.map((country) => (
        <Country key={country} country={country} />
      ))}
    </g>
  );
}
